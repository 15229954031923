.ringHolder {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ringHolder.isGroup {
  top: 35px;
}

.ringHolder .ring {
  border-radius: 50%;
  background-color: transparent;
  border: 4px solid #fcb040;
  animation-name: ringing;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes ringing {
  0% {
    opacity: 1;
    width: 20px;
    height: 20px;
  }
  100% {
    opacity: 0;
    width: 75px;
    height: 75px;
  }
}
