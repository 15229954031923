:global(.tc-AppSelector) .settingsGroup {
  margin-bottom: 20px;
}

:global(.tc-AppSelector) .settingsGroupTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 40px;
  padding-top: 20px;
}

:global(.tc-AppSelector) .settingsGroupGrid {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1700px;
  padding: 0 20px;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 1550px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

:global(.tc-AppSelector) .settingsGroupGridItem.span {
  grid-column: span 1;

  @media only screen and (min-width: 1000px) {
    grid-column: span 2;
  }
}

:global(.tc-AppSelector) .container {
  display: flex;
  margin-bottom: 20px;
  border-left: 3px solid var(--neutral-400);
  padding-left: 20px;
}

:global(.tc-AppSelector) .container .right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}

:global(.tc-AppSelector) .container .left {
  width: 250px;
}

:global(.tc-AppSelector) .container .left .title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 7px;
  text-transform: capitalize;
}

:global(.tc-AppSelector) .container .left .lockDesc {
  color: var(--neutral-700);
  font-size: 12px;
  margin-bottom: 7px;
  display: flex;
}

:global(.tc-AppSelector) .container .left .lockDesc svg {
  fill: var(--neutral-700);
  width: 12px;
  margin-right: 3px;
}

.container .left .description {
  font-size: 11px;
}
