.mentionMemberFloatingModal {
  position: absolute;
  bottom: 100%;
  transition: opacity 200ms ease, height 200ms ease;
  opacity: 1;
  width: 100%;
}

.hideMentionMemberFloatingModal {
  height: 0;
  opacity: 0;
}
