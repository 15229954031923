body .button {
  display: flex;
  align-items: center;
}

.button .plusIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--neutral-1000);
  height: 28px;
  width: 28px;
  border-radius: 50px;
}

.plusIcon .plusIconAvatar {
  fill: var(--neutral-100);
}
