.pendingUsersContainer .formInput {
  width: 200px;
  text-align: right;
}

.pendingUsersContainer .pendingUsersActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.pendingUsersContainer .noPendingUsersActions {
  justify-content: flex-end;
}

.pendingUsersContainer .pendingUsersButtonsSeparator {
  margin: 0 10px;
  border-right: 1px solid var(--neutral-1000);
}

.pendingUsersContainer .pendingUsersButtons {
  flex: 1 1 auto;
  display: flex;
}

.pendingUsersContainer .pendingUsersUserCount {
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: var(--neutral-800);
}

.pendingUsersContainer .pendingUsersTable {
  font-size: 14px;
}
