body .root {
}

.root .headerSeparator {
  padding: 20px;
}

.root .field {
  display: flex;
  align-items: center;
  margin: 25px 0;
  padding: 5px 0;
  clear: both;
  overflow: hidden;
}

.root .passwordToolTipContainer {
  position: absolute;
  z-index: 9999999;
  background-color: var(--neutral-100);
  margin-top: 160px;
  margin-left: 300px;
}

.root .input {
  width: 300px;
}

.root .label {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  padding: 0 0 0 20px;
  text-align: left;
  float: left;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

.root .buttonContainer {
  border-top: solid 1px var(--neutral-400);
  padding: 20px 0 30px 0;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.root .canceContainer {
  margin-left: 20px;
  display: inline;
}
