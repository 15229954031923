:root {
  /* Neutrals */
  --neutral-100: #ffffff;
  --neutral-100-rgb: 255, 255, 255;
  --neutral-150: #f8f8f8;
  --neutral-200: #f4f4f4;
  --neutral-300: #e6e6e6;
  --neutral-400: #dadada;
  --neutral-500: #bbbbbb;
  --neutral-500-rgb: 187, 187, 187;
  --neutral-600: #a5a5a5;
  --neutral-700: #909090;
  --neutral-800: #737373;
  --neutral-900: #565559;
  --neutral-950: #4d4d4d;
  --neutral-1000: #35333c;

  /* Cool Neutrals */
  --cool-neutral-100: #f7fdff;
  --cool-neutral-200: #f3f6f9;
  --cool-neutral-300: #e4f6fe;
  --cool-neutral-350: #dfefeb; /* Added by Sravanti for acknowledged bg escalation color */
  --cool-neutral-400: #b5e7fc;
  --cool-neutral-450: #dce9f4; /* Added by Les for MessageItem hover/select forwarding */
  --cool-neutral-500: #c3dbe5;
  --cool-neutral-700: #9baab6;
  --cool-neutral-800: #99a4a5;
  --cool-neutral-900: #6f7982; /* This feels more gray maybe #61798d instead */

  /* Primary */
  --primary-100: #ff8d87;
  --primary-300: #df655f;
  --primary-500: #f22f29;
  --primary-700: #d92a25;
  --primary-900: #af241e;

  /* Scheduling Primary */
  --scheduling-primary-100: #42a7c6;
  --scheduling-primary-300: #5492a8;
  --scheduling-primary-500: #48788d;
  --scheduling-primary-700: #3d5f72;

  /* Secondary */
  --secondary-100: #b2dcff;
  --secondary-200: #80c4ff;
  --secondary-300: #4dadff;
  --secondary-500: #058cff;
  --secondary-600: #0070d0; /* a hover for 500 (Alerts) */
  --secondary-700: #3c82cd;
  --secondary-900: #2d6199; /* often the hover for 500 replacing 276db8 */
  --secondary-1000: #182c4c;

  /* Message Status */
  --message-status-read: #418a46;
  --message-status-sent: #4696d8;
  --message-status-delivered: #489eb4;

  /* Patient Network */
  --patient-network-100: #f6fdff;
  --patient-network-200: #f0fbff; /*#f0f5f6;*/
  --patient-network-300: #e1ebee;
  --patient-network-400: #cdd9de;
  --patient-network-500: #dfefeb;
  --patient-network-600: #759cbb; /* patient network hover */
  --patient-network-700: #4a657b;
  --patient-network-800: #4078a5; /* Added By Les used for patient-network-700 hover */

  /* Success */
  --success-50: #9fdd82;
  --success-70: #74ce4a;
  --success-100: #99ffc7;
  --success-300: #57d991;
  --success-500: #04a74d;
  --success-700: #037335;
  --success-900: #024d23;

  /* Errors */
  --errors-50: #ffebea;
  --errors-100: #ffc5c2;
  --errors-300: #ff7d76;
  --errors-500: #db524d;
  --errors-700: #a83f3b;
  --errors-900: #752c29;

  /* Teams */
  --teams-100: #c2dabf;
  --teams-900: #597752;

  /* ADDED BY LES */

  /* ESCALATION */
  --escalation-50: #d6e9e4;
  --escalation-100: #00918a;
  --escalation-200: #67bdb9;

  /* SMS OPT OUT */
  --opt-out-100: #c17d62;

  /* Orange Shades (Used for escalations)- some colors may not be from the palette */
  --orange-100: #f6edde;
  --orange-200: #fdf2d1;
  --orange-500: #d79333;

  --quick-reply-700: #3c82cd;
  --quick-reply-900: #2d6199;
}

.theme-dark {
  /* Neutrals */
  --neutral-100: #2b2b2b;
  --neutral-100-rgb: 43, 43, 43;
  --neutral-150: #303030;
  --neutral-200: #353535;
  --neutral-300: #404040;
  --neutral-400: #4d4d4d;
  --neutral-500: #5f5f5f;
  --neutral-500-rgb: 95, 95, 95;
  --neutral-600: #737373;
  --neutral-700: #a5a5a5;
  --neutral-800: #bbbbbb;
  --neutral-900: #dadada;
  --neutral-950: #e1e1e1;
  --neutral-1000: #e6e6e6;

  /* Cool Neutrals */
  --cool-neutral-100: #2e2f2f;
  --cool-neutral-200: #313233;
  --cool-neutral-300: #284551;
  --cool-neutral-400: #255d74;
  --cool-neutral-450: #343a3f;
  --cool-neutral-500: #3d565c;
  --cool-neutral-700: #9baab6;
  --cool-neutral-900: #badcf7;
  --cool-neutral-1000: #c3dbee;
  --cool-neutral-1100: #d2e4f2;

  /* Primary */
  --primary-100: #ff8d87;
  --primary-300: #f1544c;
  --primary-500: #ff2c25;
  --primary-700: #f9423d;
  --primary-900: #c63531;

  /* Secondary */
  --secondary-100: #32404f;
  --secondary-200: #3c82cd;
  --secondary-300: #0070d0;
  --secondary-500: #058cff;
  --secondary-600: #4dadff;
  --secondary-700: #80c4ff;
  --secondary-900: #b2dcff;
  --secondary-1000: #b2d6ff;

  /* Message Status */
  --message-status-read: #7ae3a0;
  --message-status-sent: #5ecbff;
  --message-status-delivered: #5eeaff;

  /* Patient Network */
  --patient-network-100: #393d40;
  --patient-network-200: #3d454c;
  --patient-network-300: #465460;
  --patient-network-400: #565e65;
  --patient-network-500: #475e62;
  --patient-network-600: #8ebde4;
  --patient-network-700: #b0d8f8;
  --patient-network-800: #81c0f2;

  /* Errors */
  --errors-50: #593533;
  --errors-100: #693431;
  --errors-300: #a13933;
  --errors-500: #db524d;
  --errors-700: #ff7d76;
  --errors-900: #ffc5c2;

  /* ESCALATION */
  --escalation-50: #374847;
  --escalation-100: #67bdb9;
  --escalation-200: #00918a;

  /* Teams */
  --teams-100: #597752;
  --teams-900: #c2dabf;
}
