.locationsTab {
  margin: 20px 0 10px 38px !important;
  border-bottom: 1px solid var(--neutral-200) !important;
}

.tabButtonBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 150px;
}

.barBG {
  background-color: var(--neutral-100);
}

.locationsTab .tabButton {
  font-weight: 400;
  font-family: Inter, serif;
  font-size: 14px;
}

.locationsTab .button {
  border: none;
  min-height: 33px;
  min-width: 81px;
  padding: 25px 6px 12px;
}

.locationsTab .isSelected {
  border-bottom: 1px solid var(--primary-500);
}
