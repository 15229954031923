body .root {
  color: var(--secondary-500);
  cursor: pointer;
  text-decoration: none;
}

body .root:hover {
  text-decoration: underline;
  color: var(--secondary-900);
}
