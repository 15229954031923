body .inputRoot {
  position: relative;
  width: 100%;
}

.inputRoot .input {
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-300);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: border linear 0.2s, box-shadow linear 0.2s;
  display: inline-block;
  min-height: 20px;
  padding: 4px 6px;
  font-size: 14px;
  line-height: 20px;
  color: var(--neutral-900);
  vertical-align: middle;
  border-radius: 4px;
  width: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

.inputRoot .hasClearButton {
  padding: 4px 25px 4px 6px;
}

.inputRoot .input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--neutral-600);
  opacity: 1; /* Firefox */
}

.inputRoot .input:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--secondary-500);
}

.inputRoot .buttonContainer {
  position: absolute;
  top: 7px;
  right: 6px;
  width: 16px;
  height: 16px;
}

.inputRoot .clearButton {
  background: url('../../images/sprite_icons.png') no-repeat top center;
  background-position: -40px -606px;
  cursor: pointer;
}

body:global(.theme-dark) .inputRoot .clearButton {
  background: url('../../images/sprite_icons_dark.png') no-repeat top center;
  background-position: -40px -606px;
}

.inputRoot .loadingContainer {
  margin-top: 5px;
}

.inputRoot .loadingImage {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  width: 16px;
  height: 16px;
}

.inputRoot .input:disabled {
  background-color: var(--neutral-400);
  cursor: not-allowed;
  opacity: 0.7;
}
