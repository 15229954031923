body .root {
  font-size: 21px;
  border: none;
  line-height: 40px;
  font-family: 'Open Sans', sans-serif;
  color: var(--neutral-1000);
  text-rendering: optimizelegibility;
}

.root .arrow {
  margin: 0 10px;
  font-size: 21px;
}
