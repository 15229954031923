body .c2cControls {
  display: flex;
  flex-direction: row;
}

.c2cControls .c2cMaskType {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.c2cControls .c2cProxyNumber {
  margin: 0 15px;
  margin-top: 6px;
}

.c2cControls .c2cMaskType label {
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  margin-top: 6px;
  margin-bottom: 3px;
}

.c2cControls .c2cMaskType .sublabel {
  color: rgba(0, 0, 0, 0.3);
  font-size: 11px;
  margin-top: 5px;
}

.c2cControls .countryCodeContainer {
  display: flex;
  flex-direction: column;
}

.c2cControls .customNumberInputContainer {
  position: relative;
}

.c2cControls .c2cCallingNumberAreaCodeErr input {
  color: var(--errors-500);
}

.c2cControls .c2cCallingNumberErr input {
  padding-left: 32px;
  color: var(--errors-500);
}

.c2cControls .c2cCallingNumber input {
  padding-left: 32px;
}

.c2cControls .plusOne {
  position: absolute;
  top: 7px;
  left: 11px;
}

.c2cControls .verifyCustomContainer {
  max-width: 210px;
}

.c2cControls .verifyCustomContainer label {
  color: var(--neutral-1000);
  font-weight: 600;
  line-height: 15px;
  margin: 5px 0;
  font-size: 11px;
  display: block;
}

.c2cControls .verifyCustomContainer p {
  padding-bottom: 5px;
  font-size: 11px;
  color: var(--neutral-1000);
  line-height: 15px;
}

.c2cControls .verificationCodeInput input {
  margin-top: 10px;
  letter-spacing: 8px;
  cursor: initial;
  padding-left: 10px;
}

.c2cControls .verificationCodeInput input:focus {
  outline: none;
}
