body .root {
  margin: 0 5px 0 0;
  font-weight: 300;
  font-size: 14px;
}

.red {
  color: var(--primary-500);
}

.grey {
  color: var(--neutral-500);
}

.green {
  color: var(--success-500);
}

.yellow {
  color: #ffa500;
}
