.scimProvisioning .section {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  font-size: 13px;
  gap: 12px;
}

.scimProvisioning .header {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}

.scimProvisioning .label {
  display: block;
  width: 90px;
  flex-shrink: 0;
}

.scimProvisioning .input {
  display: block;
  width: 264px;
  flex-shrink: 0;
  flex-grow: 4;
}

.scimProvisioning .settingRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 520px;
}

.scimProvisioning .attributeRow {
  display: flex;
  flex-direction: row;
}

.scimProvisioning .attributeName {
  font-weight: bold;
  width: 110px;
  flex-shrink: 0;
}

.scimProvisioning .subAttributeWrapper {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.scimProvisioning .subAttributeTitle {
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
}

.scimProvisioning .rowElements {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}
