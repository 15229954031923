.toastContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.toastHolder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
}

.toast {
  overflow: hidden;
  display: flex;
  align-items: center;
  animation-name: alert;
  animation-duration: 3s;
  width: 20px;
}

@keyframes alert {
  0% {
    height: 0;
    padding: 0 30px;
  }

  20% {
    height: 100%;
    padding: 10px 30px;
  }

  80% {
    height: 100%;
    padding: 10px 30px;
  }

  100% {
    height: 0;
    padding: 0 30px;
  }
}

.SUCCESS,
.WARNING,
.FAILURE {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAoCAYAAAAPOoFWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPZJREFUeNq81tsOgjAMANB2ov7/7ypaN7IlIwi9rGuT8QSc9EIDAsAznxvY4pXPKr05RUE5MEVB+TyWfCEl9LZApYopCmo9C4FKSMtYoI8Bwv79aQJU4l6hXXCZrQbokJEksxHo9KMOgc6w1atHXM8K9DVC7FQnJ0i8iK3QooGgbnyKgMDygBWyYFZoqx4qS27KqLZJjA1D0jK6QJcYEQEiWv9PGkTsbqxQ8oT+ZtZB6AkdsJnQDnMoHXHLGKOgDYuCWmYhEERCI5gaamW0bnHdA3k2ltlIN+2qKRyCND0bhqSYCyTB3CAOc4WusBEIpkeBuPgJMAAX8Hs1NfqHRgAAAABJRU5ErkJggg==')
    repeat-x scroll left top var(--neutral-100);
  border-radius: 5px !important;
  border: solid 1px !important;
  min-width: 100px !important;
  text-align: center !important;
  width: auto !important;
}

.SUCCESS {
  background-color: rgb(145, 238, 144);
  color: rgb(5, 100, 0);
  border-color: rgb(81, 194, 79);
}

.WARNING {
  background-color: #ffeaa8;
  color: #826200;
  border-color: #ffc237;
}

.FAILURE {
  background-color: var(--primary-500);
  color: var(--neutral-100);
  font-weight: bold;
  border-color: rgb(139, 1, 0);
}
