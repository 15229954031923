.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  max-width: 80%;
  margin: auto !important;
  font-size: 14px !important;
}

.contentContainer .memberToAddDisplayName {
  color: var(--secondary-300);
}

.membersWrapper {
  max-height: 300px;
  height: 100%;
  flex: 1 1;
  width: 100%;
  overflow: auto;
  margin-top: 16px !important;
}

.membersToAddContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.membersToAddItem {
  padding: 0;
}

.membersToAddItem span {
  color: var(--secondary-300);
  cursor: pointer;
}
