.radioInputContainer input[type='radio'] {
  display: none;
}

.radioInputContainer .label {
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  margin-right: 35px;
}

.radioInputContainer input[type='radio'] + label span {
  display: inline-block;
  width: 18px;
  height: 25px;
  background: url('../../images/sprite_icons.png') no-repeat top left;
  background-position: 0 -1615px;
  margin-right: 3px;
}

body:global(.theme-dark) .radioInputContainer input[type='radio'] + label span {
  background: url('../../images/sprite_icons_dark.png') no-repeat top left;
  background-position: 0 -1615px;
}

.radioInputContainer input[type='radio']:checked + label span {
  background: url('../../images/sprite_icons.png') no-repeat top left;
  background-position: -17px -1615px;
}

body:global(.theme-dark) .radioInputContainer input[type='radio']:checked + label span {
  background: url('../../images/sprite_icons_dark.png') no-repeat top left;
  background-position: -17px -1615px;
}
