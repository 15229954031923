body .root {
  font-family: 'Open Sans', sans-serif;
}

body .small {
  font-size: 11px;
  line-height: 15px;
  width: 210px;
  margin-top: 7px;
}

body .large {
  font-size: 14px;
  font-weight: bold;
  width: 250px;
  padding-right: 3px;
}

body .top {
  align-self: flex-start;
}

body .bottom {
  align-self: flex-end;
}
