body .root {
}

.root .tableActionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.root .buttonContainer {
  display: flex;
  margin-top: 18px;
  margin-bottom: 15px;
}

.root .pipe {
  line-height: 30px;
  padding: 0 8px 0 4px;
}

.root .removingUsers {
  align-items: center;
  color: var(--neutral-800);
  cursor: not-allowed;
  display: flex;
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  margin: 0 5px 0 0;
  padding: 5px 10px;
}

.root .loadingImage {
  background: none;
  max-width: 100%;
  margin-right: 6px;
  vertical-align: middle;
  border: 0;
  width: 16px;
  height: 16px;
}

.root .userCount {
  color: var(--neutral-800);
  padding-right: 20px;
}
