body .container {
  width: 450px;
  padding-left: 10px;
}

.container .label {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  color: var(--neutral-1000);
  font-family: 'Open Sans', sans-serif;
  line-height: 20px;
}
