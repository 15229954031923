.tableContainer table {
  outline: none;
  border: none;
  border-spacing: none;
  color: var(--neutral-1000);
  width: 100%;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  text-align: left;
}

.emptyMessage {
  text-align: center;
  padding: 20px;
}

.tableContainer tbody tr:nth-child(odd) {
  background-color: var(--neutral-150);
}

.tableContainer tbody tr:hover {
  background-color: var(--neutral-300);
}

.tableContainer th,
.tableContainer td {
  padding: 0 5px;
}

.tableContainer th {
  font-weight: bold;
  padding: 5px 5px;
}

.tableContainer td {
  padding: 15px 5px;
  vertical-align: middle;
  word-break: break-word;
}

.tableContainer table .checkbox {
  padding: 0 5px;
  text-align: center;
  cursor: pointer;
  width: 25px;
}

.tableContainer table .imageCell {
  text-align: center;
  width: 35px;
}

.tableContainer table .imageCell img {
  height: 25px;
}

.tableContainer table .iconCell {
  padding: 10px;
  width: 36px;
  text-align: center;
}

.tableContainer .paginationContainer {
  width: 100%;
  text-align: center;
  margin-top: 25px;
}

.paginationContainer .paginationButton {
  padding: 4px 15px;
  background-color: var(--cool-neutral-100);
  border: solid 1px var(--neutral-400);
  color: var(--neutral-700);
  border-radius: 3px;
  cursor: pointer;
  width: 100px;
  margin: 0 auto;
  display: inline-block;
}

.paginationContainer .paginationButton:hover {
  background-color: var(--neutral-150);
  border: solid 1px var(--neutral-700);
  color: var(--neutral-1000);
}

body:global(.theme-dark) .tableContainer .editIcon {
  background: url('../../images/sprite_icons_dark.png') no-repeat left center;
  background-position: 7px -990px;
}

.tableContainer .editIcon {
  background: url('../../images/sprite_icons.png') no-repeat left center;
  background-position: 7px -990px;
  width: 30px;
  height: 35px;
  cursor: pointer;
  display: flex;
}

.tableContainer .addIcon {
  background: url('../../images/sprite_icons.png') no-repeat top center;
  background-position: -40px -606px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  display: flex;
  transform: rotate(45deg);
}

body:global(.theme-dark) .tableContainer .addIcon {
  background: url('../../images/sprite_icons_dark.png') no-repeat top center;
  background-position: -40px -606px;
}
