body .root {
}

.root .headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px 10px 0;
  position: sticky;
  top: 0;
  background-color: var(--neutral-100);
  z-index: 9;
}
.root .headerContainer[sticky] {
  border-bottom: solid 1px var(--neutral-400);
  box-shadow: 0 6px 4px -4.6px rgb(0, 0, 0, 40%);
}

.root .header {
  display: flex;
  align-items: center;
}
.root .smallAvatar {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
.root .actionButtons {
  display: flex;
  height: 30px;
}
.root .userSettings {
  margin-top: 40px;
  margin-bottom: 20px;
  border-top: 1px solid var(--neutral-400);
  border-bottom: 1px solid var(--neutral-400);
}

.root .tooltip {
  position: relative;
  display: inline-flex;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  border: 2px solid var(--neutral-1000);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  color: var(--neutral-1000);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.root .tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: var(--neutral-900);
  color: var(--neutral-100);
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.root .tooltipText::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: var(--neutral-900) transparent transparent transparent;
}

.root .tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.root .checkBoxContainer {
  display: flex;
  width: 100%;
}

.root .userInfo {
  display: flex;
  padding-top: 25px;
}
.root .avatar {
  padding: 0 20px;
}

.root .settingsForm {
}

.root .field {
  display: flex;
  padding-bottom: 15px;
  align-items: center;
}

.root .phoneNumberFields {
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;
}

.root .settingReadInfo {
  width: 100%;
}

.root .phoneNumbers {
  background-color: var(--neutral-200);
  margin-top: 10px;
  padding: 10px 0;
}

.root .deleteIcon {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  cursor: pointer;
}

.root .deleteIcon svg circle {
  fill: var(--neutral-200);
}

.root .deleteIcon svg path {
  fill: var(--neutral-1000);
}

.root .phoneNumber {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-weight: bold;
}

.root .userSettingsForm {
  margin-top: 40px;
}

.root .pagerNumber {
  margin-bottom: 10px;
}

.root .patientEngagementTitle {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
}

.root .clickToCallAdmin {
  margin-left: 10px;
}
