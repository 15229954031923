.container {
  display: flex;
  margin-right: 10px !important;
  justify-content: space-between;
  align-items: center;
  color: #fff !important;
  font-weight: 600 !important;
}

.expandedContainer {
  color: #dadada !important;
}

.formattingIcon {
  width: 25px !important;
  height: 25px !important;
  font-size: 15px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: #dadada !important;
}

.expandedFormattingIcon,
.formattingIcon:hover,
.icon:hover {
  color: #3080df !important;
}

.icon {
  width: 25px !important;
  height: 25px !important;
  border: none !important;
  border-radius: 15px !important;
  font-size: 15px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  color: #dadada !important;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
}

.italic {
  font-style: italic !important;
  margin-left: -5px !important;
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 19px !important;
}

.codeLeft,
.codeRight {
  font-size: 19px !important;
}
