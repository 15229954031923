body .bodyText {
  white-space: pre-line;
  text-align: center;
}

body .form {
  display: flex;
  flex-direction: column;
  padding: 20px 50px 50px;
}

body .hyperText {
  color: #2c8cff;
}

body .hyperText:hover {
  color: #2c8cff;
}

body .buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

body .continue {
  height: 35px !important;
  width: 350px !important;
  background-color: #2b70c4 !important;
  border-radius: 4px !important;
  border: 1px solid #fff !important;
  box-sizing: border-box !important;
  color: #fff !important;
  display: flex !important;
  font-size: 12px !important;
  justify-content: center !important;
  margin: 20px 0;
  text-align: center !important;
}
