.parent {
  display: block;
}

.dropDown {
  background-color: var(--neutral-100);
  border: 1px solid var(--neutral-700) !important;
  box-sizing: border-box !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  cursor: pointer;
  padding: 6px 10px !important;
}

.item:hover {
  background-color: var(--neutral-200);
}

.disabledItem:hover {
  background-color: var(--neutral-100);
}
