body .root {
}

.root .container {
  display: flex;
  margin-top: 25px;
}

.root .leftContainer {
  width: 280px;
}

.root .buttonContainer {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.root .cancelContainer {
  display: inline;
  margin-left: 20px;
}

.root .file {
  padding: 5px 0;
}

.root .rightContainer {
  width: 600px;
}

.root .bold {
  font-size: 14px;
  font-weight: bold;
}

.root .dashBox {
  border: dashed 1px var(--neutral-700);
  background-color: var(--neutral-200);
  padding: 10px;
  margin: 10px 0;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  width: 635px;
}

.root .table {
  margin-top: 20px;
}
