.header {
  color: var(--primary-300);
  font-size: 18px !important;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 34px 32px 0 !important;
}

.body {
  padding: 0;
}

.footer {
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: var(--neutral-150);
  margin-top: auto !important;
}

.footerLeft {
  flex: 1;
}

.footerRight {
  display: flex;
  padding-right: 30px !important;
}

.saveButton {
  color: var(--primary-300);
  width: 92px !important;
  height: 34px !important;
  border-radius: 6px;
  border: solid 1px !important;
  text-transform: uppercase;
  font-size: 10px !important;
  width: 78px !important;
}

.saveButton:disabled,
.saveButton:hover:disabled {
  cursor: default;
  border: solid 1px var(--neutral-300) !important;
  color: var(--neutral-300);
  background-color: var(--neutral-100);
}

.saveButton:hover {
  color: var(--neutral-100);
  background-color: var(--primary-300);
}

.cancelButton {
  color: var(--cool-neutral-700);
  background-color: var(--neutral-100);
  width: 92px !important;
  height: 34px !important;
  border-radius: 6px;
  border: solid 1px !important;
  text-transform: uppercase;
  font-size: 10px !important;
  width: 92px !important;
  margin-right: 10px !important;
}

.cancelButton:hover {
  color: var(--neutral-100);
  background-color: var(--cool-neutral-700);
}

.bodyText {
  color: var(--neutral-1000);
  padding: 15px 52px 70px 52px !important;
  display: flex;
  flex-direction: column;
  font-size: 12px !important;
  text-align: center;
}

.error {
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center;
  color: var(--neutral-100);
  width: auto;
  height: 27px;
  background: var(--primary-100);
  border: 1px solid var(--primary-300) !important;
  border-radius: 6px;
  padding-top: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.errorContainer {
  display: flex;
  height: 30px;
  justify-content: center;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  pointer-events: auto;
  cursor: pointer;
  color: var(--primary-300) !important;
  margin: 34px 32px 0 !important;
}

.close:focus-visible {
  outline: 3px solid var(--secondary-500);
}

.bold {
  font-weight: bold;
}
