body .numberRoot {
  position: relative;
  display: inline-block;
}

.numberRoot .input {
  border: solid 1px var(--neutral-400);
  line-height: 20px;
  padding: 4px 6px;
}
