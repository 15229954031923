.fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.disabled {
  cursor: pointer !important;
}

.field {
  width: 210px !important;
  margin-right: 5px !important;
}

.viewHeader,
.viewSubheader {
  margin-bottom: 10px !important;
}

.error {
  font-size: 13px !important;
  line-height: 20px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.dropdown {
  display: flex;
  flex-direction: column;
  margin-top: 15px !important;
  padding-top: 10px !important;
  margin-right: 5px !important;
}

.chooseANumberText {
  font-size: 12px !important;
  font-style: italic !important;
}

.buttonWrapper {
  margin-top: 10px !important;
}

.permissionsList {
  display: flex !important;
  flex-direction: column !important;
  height: 250px;
  justify-content: space-between;
}
