.personaSelect {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px),
    calc(100% - 2.5em) 0.35em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.personaSelect:global(.has-value) {
  background-color: var(--neutral-200);
}

.personaSelect :global(.Select-value) {
  position: absolute;
  top: 7px;
  left: 8px;
}

.personaSelect:global(.is-focused) :global(.Select-value) {
  opacity: 0.5;
}
