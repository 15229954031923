/* for tinymce dropdowns to appear */
:global(.tox) {
  position: static !important;
}

.welcomeEmail hr {
  border-bottom: solid 1px var(--neutral-400);
  margin: 20px 0;
}

.welcomeEmail .header {
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}

.welcomeEmail .subheader {
  font-size: 13px;
  margin-bottom: 10px;
}

.welcomeEmail .section {
  max-width: 608px;
}

.welcomeEmail .inputCountContainer {
  margin: 10px 0;
}

.welcomeEmail .editorContainer {
  margin-bottom: 70px;
}

.welcomeEmail .saveSettingsButtonContainer {
  margin-top: 20px;
}

.welcomeEmail .welcomeEmailMissedNotice {
  margin: 10px 0;
}

.welcomeEmail label {
  display: block;
  margin-bottom: 5px;
}

.welcomeEmail .templateRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.welcomeEmail .subjectColumn {
  flex: 0 0 608px;
}

.welcomeEmail .logoColumn {
  flex: 0 1 auto;
  padding-top: 14px;
  padding-left: 20px;
}

.welcomeEmail .logoColumn img {
  margin-top: 5px;
  max-width: 100px;
  display: block;
}

.welcomeEmail .welcomeEmailRadioContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
