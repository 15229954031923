.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
}

.modalContainerBG {
  position: absolute;
  background: var(--neutral-1000);
  opacity: 0.8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal .root {
  background-color: var(--neutral-100);
  border-radius: 5px;
  border: 1px solid var(--neutral-300);
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 9%);
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
  outline: none;
  position: relative;
}

.modal .small {
  width: 450px;
}

.modal .medium {
  width: 500px;
}

.modal .large {
  width: 750px;
}

.modal .footer {
  background-color: var(--neutral-200);
  border-top: 1px solid var(--neutral-400);
  display: flex;
  font-weight: 500;
  justify-content: flex-end;
  padding: 15px 9px;
  position: relative;
}

.modal .headerContainer {
  border-bottom: 1px solid var(--neutral-300);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.modal .header {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal .close {
  cursor: pointer;
  float: right;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: var(--neutral-1000);
  text-shadow: 0 1px 0 var(--neutral-100);
  opacity: 0.2;
}

.modal .body {
  border-top: 1px solid var(--neutral-300);
  color: var(--neutral-1000);
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  padding: 20px 15px;
  position: relative;
  text-overflow: ellipsis;
}
