.root {
  display: flex;
  flex-direction: row;
}

.hierarchyContainer {
  display: flex;
  height: 55px;
  align-items: center;
  flex-grow: 1;
}

.hierarchyContainer .plusContainer {
  visibility: hidden;
  display: flex;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  align-self: center;
  justify-content: center;
}

.hierarchyContainer:hover .plusContainer {
  visibility: visible;
}

.hierarchyContainer .plusContainer:hover {
  visibility: visible;
  background-color: var(--neutral-400);
}

.hierarchyContainer:hover .plusIcon {
  width: 10px;
  height: 10px;
  align-self: center;
}

.hierarchyContainer .toolTipContainer {
  align-self: center;
}

.hierarchyContainer .newLocationTooltip {
  margin-top: 5px;
}

.orgName {
  margin: 8px;
}

.firstSubString {
  border: 1px solid var(--neutral-1000);
}

.container {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.hierarchyContainer .hoverContainer {
  display: flex;
  flex-grow: 1;
  height: 55px;
  padding: 8px;
  border-radius: 6px;
}

.hoverContainer:hover {
  background-color: var(--neutral-200);
}

.container .displayDropdown {
  background-color: var(--neutral-200);
}

.container .noDropdown {
  margin-left: 44px;
}

.hoverContainer .iconContainer {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  align-self: center;
  border-radius: 2px;
}

.iconContainerChildren:hover {
  background-color: var(--neutral-400);
  cursor: pointer;
}

.hierarchyContainer .line {
  margin-right: 8px;
}

.iconContainer .icon {
  margin-top: 8px;
}

.hierarchyContainer .first {
  flex-grow: 1;
}

.hierarchyContainer .second {
  margin-left: 48px;
  flex-grow: 1;
}

.hierarchyContainer .third {
  margin-left: 108px;
  flex-grow: 1;
}

.hierarchyContainer .forth {
  margin-left: 214px;
  flex-grow: 1;
}

.hierarchyContainer .nameContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-grow: 1;
  align-self: center;
}

.hierarchyContainer .unitName {
  padding-right: 20px;
  color: var(--neutral-800);
  height: 15px;
  font-family: Inter, serif;
  font-weight: 400;
  font-size: 12px;
}

.hierarchyContainer .rightSideContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hierarchyContainer .title {
  font-size: 16px;
}

.hierarchyContainer .subtitle {
  font-size: 12px;
  color: var(--neutral-800);
}

.hierarchyContainer .optionsContainer {
  display: flex;
  margin-right: 7px;
  align-items: center;
}

.hierarchyContainer .optionsContainer {
  visibility: hidden;
  align-self: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.hierarchyContainer:hover .optionsContainer {
  visibility: visible;
}

.hierarchyContainer .optionsContainer svg {
  color: var(--neutral-200);
}

.hierarchyContainer .optionsContainer:hover svg {
  color: var(--neutral-400);
}
