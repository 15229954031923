body .actionBar {
  display: flex;
  flex-direction: column;
  position: relative;
}

.actionBar .barBG {
  min-height: 42px;
  background-color: var(--neutral-800);
  margin-top: 10px;
}

.actionBar .tabButtonBar {
  margin: 0 10px;
  margin-top: -10px;
}

.barInput {
  position: absolute;
  right: 30px;
  top: 16px;
}
