.item {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.item:hover {
  background: var(--cool-neutral-300);
}

.item:focus-visible {
  outline: 3px solid var(--secondary-500);
  background: var(--cool-neutral-300);
  margin: 0 3px 3px 3px;
}

.results {
  background: var(--neutral-150);
  width: 350px;
  overflow: hidden;
}

.fullBorder.fullBorder {
  border-bottom: 1px solid var(--neutral-300);
}

.partialBorder {
  position: relative;
}

.partialBorder:before {
  content: '';
  border-bottom: 1px solid var(--neutral-300);
  position: absolute;
  width: 85%;
  left: 15%;
  bottom: 0;
}

.callButton.callButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}

.callButton.callButton:focus-visible {
  outline: 3px solid var(--secondary-500);
}

.entityDetail {
  flex: 1;
}

.entityDetail:focus-visible {
  outline: 3px solid var(--secondary-500);
}

.isDisabled {
  opacity: 0.5;
}

.smsOptedOut.smsOptedOut {
  padding-left: 50px;
  margin-top: -5px;
}

.patientReferencePill {
  margin-right: 8px;
  width: 280px;
}

mark {
  background-color: transparent;
}

.results .patientHighlight mark {
  color: var(--neutral-950);
  background-color: var(--cool-neutral-300);
}

.results .providerHighlight {
  color: var(--neutral-700);
}

.results .providerHighlight mark {
  color: var(--neutral-1000);
}
.customizableEntity {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.textContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 120px;
  width: 100%;
}

.nameContainer {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  width: 100%;
}

.notInGroupText {
  font-size: 12px !important;
  text-wrap: wrap;
  color: var(--secondary-900);
}
