body .orgSettingsListItem {
  display: flex;
  align-items: center;
}

.orgSettingsListItem .textContent {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}
