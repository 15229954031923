body .forumsContainer {
  height: 100%;
  position: relative;
}

.forumsContainer .pageTitle {
  font-size: 24px;
  margin-bottom: 15px;
}

.forumsContainer .forumsCreate {
  margin: 20px 0;
}

.forumsContainer .forumsTable {
  margin: 5px 0;
  font-size: 13px;
}
