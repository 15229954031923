body .root {
  background-color: var(--neutral-200);
  height: 100%;
  color: var(--neutral-1000);
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

body .container {
  background-color: var(--neutral-200);
  padding: 0 30px 30px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

body .loadingContainer {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.root .dropdownContainer {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 5px;
}

.root .viewContainer {
  height: 100%;
  display: flex;
  width: 100%;
}
