.table.table {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.facilityGroup {
  display: flex;
  flex-direction: column;
}

.facilityRow {
  display: flex;
  align-items: center;
}

.facilityRow .facilityDetails {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 55px;
  align-items: center;
  padding: 8px;
  margin-left: 8px;
}

.facilityOptions {
  position: absolute;
  left: -80px;
}
.unitOptions {
  position: absolute;
  left: -105px;
}
.roomOptions {
  position: absolute;
  left: -140px;
}

.facilityDetails:hover,
.unitDetails:hover,
.roomDetails:hover {
  background-color: var(--neutral-200);
}

.showOptionsBackground {
  background-color: var(--neutral-200);
}

.facilityDetails .options.showOptionsMenu,
.unitDetails .options.showOptionsMenu,
.roomDetails .options.showOptionsMenu {
  visibility: visible;
}

.plus {
  visibility: hidden;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.plus:hover {
  background-color: var(--neutral-400);
}

.plus img {
  width: 11px;
  height: 11px;
}

.facilityRow:hover .plus,
.facilityChildren:hover .plus,
.facilityRow:hover .options,
.unitRow:hover .options,
.roomRow:hover .options {
  visibility: visible;
}

.facilityDetails .facilityCollapse {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
  display: flex;
}

.facilityCollapse:hover {
  background-color: var(--neutral-400);
}

.sideArrow {
  width: 7px;
  height: 14px;
}

.facilityGroup .downArrow {
  height: 9px;
  width: 14px;
  margin-top: 3px;
}

.facilityInfo {
  padding-top: 3px;
  display: flex;
  flex-direction: row;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-grow: 1;
  align-self: center;
}

.facilityInfo .subtitle {
  font-size: 12px;
  color: var(--neutral-800);
}

.facilityInfo .facilityName,
.unitInfo .unitName {
  color: var(--neutral-1000);
  margin-right: 8px;
}

.facilityInfo .facilityLabel,
.unitInfo .unitLabel {
  margin-top: 3px;
  font-size: 12px;
  line-height: 15px;
  color: var(--neutral-800);
  min-width: 50px;
}

.unitRow .options,
.facilityRow .options,
.roomRow .options {
  visibility: hidden;
  display: flex;
  margin-right: 7px;
  align-items: center;
  position: relative;
}

.options svg {
  color: var(--neutral-200);
}

.options:hover svg {
  color: var(--neutral-400);
}

.facilityChildren .addUnitRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 8px;
  background: var(--neutral-150);
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.facilityChildren .addUnitRow:hover {
  background: var(--neutral-200);
}

.addUnit {
  background: var(--neutral-300);
  width: 24px;
  height: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.facilityChildren .addUnitLabel {
  margin-left: 16px;
}

.facilityGroup .facilityChildren {
  margin-left: 50px;
}

.facilityChildren .line {
  margin-right: 8px;
}

.unitRow {
  height: 55px;
  display: flex;
  align-items: center;
}

.facilityChildren .unitDetails {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 55px;
  border-radius: 6px;
  padding: 10px 5px 10px 8px;
}

.facilityChildren .unitCollapse {
  width: 24px;
  height: 24px;
  justify-content: center;
  cursor: pointer;
  padding-top: 5px;
  display: flex;
  margin-left: 11px;
  margin-right: 15px;
}

.unitCollapse:hover {
  background-color: var(--neutral-400);
}

.unitInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.unitGroup .roomRow {
  display: flex;
  margin-left: 56px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.unitGroup .roomDetails {
  display: flex;
  flex-grow: 1;
  padding: 0px 10px 0px 8px;
  border-radius: 6px;
}

.roomInfo {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 35px;
}

.unitGroup .roomLabel {
  font-size: 16px;
  line-height: 15px;
  color: var(--neutral-700);
  margin-right: 5px;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
