body .location {
  margin: 40px;
}

.location .locationHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 40px;
}

.location .locationHeaderTextAndRefresh {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.location .refreshIcon {
  margin: 0 10px;
  cursor: pointer;
}

.parentContainer {
  align-items: flex-start;
}

.orgTable .addContainer {
  display: flex;
  background: var(--neutral-150);
  border-radius: 6px;
  cursor: pointer;
  height: 44px;
  padding: 10px 8px;
  align-items: center;
  margin-left: 44px;
}

.orgTable .addContainer:hover {
  background: var(--neutral-200);
}

.orgTable .imgContainer {
  background: var(--neutral-300);
  width: 24px;
  height: 24px;
  margin-right: 16px;
  padding-top: 4px;
  text-align: center;
}

.orgTable .icon {
  width: 10px;
  height: 10px;
}

.loading {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
