body .forumContainer {
  position: relative;
}

.forumContainer .forumContent {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  width: 1000px;
}

.forumContainer .modifyForumForm {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.forumContainer .formRow {
  display: flex;
  align-items: center;
  margin: 10px 0;
  position: relative;
}

.forumContainer .forumInput {
  flex: 1;
}

.forumContainer .formRow label {
  flex: 0 0 120px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.forumContainer .forumFormActions {
  display: flex;
  align-self: flex-end;
  justify-self: end;
  align-items: center;
  margin-top: 40px;
}

.forumContainer .forumMembers {
  flex: 1;
  margin-left: 40px;
}

.forumContainer .forumMembersList {
  font-size: 14px;
  background: var(--neutral-300);
  padding: 15px;
  color: var(--neutral-900);
}

.forumContainer .membersText {
  font-weight: bold;
}

.forumContainer .forumMember {
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 20px 5px 0;
}

.forumContainer .memberName {
  padding-left: 20px;
  flex: 1;
}

.forumContainer .removeMemberButton {
  cursor: pointer;
}

.forumContainer .loadingMembers {
  padding: 20px 5px 0;
}

.forumContainer .addMemberDropdownContainer {
  position: absolute;
  background: var(--neutral-300);
  border: solid 2px var(--neutral-400);
  border-radius: 5px;
  top: 35px;
  left: 130px;
  width: 370px;
  font-size: 14px;
  max-height: 300px;
  overflow: scroll;
  z-index: 1;
}

.forumContainer .userRoleContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--neutral-400);
  cursor: pointer;
  height: 57px;
  padding: 10px;
}

.forumContainer .userRoleContainer:hover {
  background: var(--neutral-100);
}

.forumContainer .imageCell {
  height: 40px;
  width: 40px;
}

.forumContainer .selectedImageCell {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.forumContainer .addMemberItem {
  padding: 10px;
}

.forumContainer .cancelButton {
  color: var(--secondary-500);
  display: inline-block;
  padding: 0 20px;
  font-size: 14px;
  cursor: pointer;
}

.forumContainer .forumAvatarContainer {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.forumContainer .forumAvatarContainer img {
  width: 50px;
  height: 50px;
}
