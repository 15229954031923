.footer {
  height: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: var(--neutral-150);
  margin-top: 45px !important;
}

.footerLeft {
  flex: 1;
}

.footerRight {
  display: flex;
  padding-right: 30px !important;
}

.header {
  color: var(--primary-300) !important;
  font-size: 18px !important;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 34px 32px 0 !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  pointer-events: auto;
  cursor: pointer;
  color: var(--primary-300) !important;
  margin: 34px 32px 0 !important;
}

.close:focus-visible {
  outline: 3px solid var(--secondary-500);
}

.saveButton {
  color: var(--secondary-700);
  width: 92px !important;
  height: 34px !important;
  border-radius: 6px;
  border: solid 1px !important;
  text-transform: uppercase;
  font-size: 10px !important;
}

.deleteButton {
  color: var(--primary-300) !important;
  width: 92px !important;
  height: 34px !important;
  border-radius: 6px;
  border: solid 1px !important;
  text-transform: uppercase;
  font-size: 10px !important;
}

.deleteButton:hover {
  color: var(--neutral-100) !important;
  background-color: var(--primary-300) !important;
}

.saveButton:disabled,
.saveButton:hover:disabled {
  cursor: default;
  border: solid 1px var(--neutral-300) !important;
  color: var(--neutral-300);
  background-color: var(--neutral-100);
}

.saveButton:hover {
  color: var(--neutral-100);
  background-color: var(--secondary-700);
}

.cancelButton {
  color: var(--cool-neutral-700);
  background-color: var(--neutral-100);
  width: 92px !important;
  height: 34px !important;
  border-radius: 6px;
  border: solid 1px !important;
  text-transform: uppercase;
  font-size: 10px !important;
  margin-right: 10px !important;
}

.cancelButton:hover {
  color: var(--neutral-100);
  background-color: var(--cool-neutral-700);
}

.body {
  color: var(--neutral-1000);
  min-height: 250px;
  display: flex;
  flex-direction: column;
  padding-top: 13px !important;
}

.bodyText {
  color: var(--neutral-1000);
  padding: 15px 52px 70px !important;
  display: flex;
  flex-direction: column;
  font-size: 15px !important;
  text-align: center;
}

.bold {
  font-weight: bold !important;
}

.inputContainer {
  padding: 15px 52px 5px !important;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inputLabel {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: Inter !important;
  font-style: normal !important;
  line-height: 15px !important;
  opacity: 0.51 !important;
  margin-bottom: 5px !important;
}

.input input {
  background: var(--neutral-100) !important;
  border: 1px solid var(--neutral-300) !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}

.error {
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: center;
  color: var(--neutral-100);
  width: auto;
  height: 27px;
  background: var(--primary-100);
  border: 1px solid var(--primary-300) !important;
  border-radius: 6px;
  padding-top: 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.errorContainer {
  display: flex;
  height: 30px;
  justify-content: center;
}

.listContainer {
  background-color: var(--neutral-100);
  border: solid 1px var(--cool-neutral-100) !important;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.09);
  color: var(--neutral-950);
  max-height: 303px;
  overflow-y: auto;
  position: absolute;
  width: 395px;
  z-index: 1;
}

.listOption {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px !important;
  height: 40px;
  padding-left: 28px !important;
}

.selectedListOption,
.listOption:hover {
  background-color: var(--cool-neutral-300);
}

.selectorContainer {
  border: solid 1px var(--neutral-300);
  border-radius: 6px;
  height: 30px;
  padding: 0 10px;
  background-color: var(--neutral-150);
  box-sizing: border-box !important;
  box-shadow: none !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.selectorContainer:hover {
  background-color: var(--cool-neutral-100);
  cursor: pointer;
}

.selectorItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  font-size: 14px;
  color: var(--neutral-900);
  font-weight: normal;
}

.selectorItemLabel {
  flex: 1;
}

.clearButton {
  padding-top: 2px;
}

.iconSecondary {
  background-color: var(--cool-neutral-100);
  border-radius: 100%;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.iconSecondary path {
  fill: var(--cool-neutral-700);
}

.iconSecondary circle {
  stroke: var(--cool-neutral-100);
}

.dropdown {
  margin-left: 3px !important;
  position: absolute;
  top: 40px;
}
