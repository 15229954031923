.root {
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.buttonContainer.outgoing {
  flex-direction: row-reverse;
}

.defaultButton.defaultButton {
  margin: 0 10px;
  opacity: 0.5;
}

.defaultButton:hover {
  opacity: 1;
}

.addContainer:hover svg {
  fill: var(--neutral-950);
}

.addContainer.addContainer {
  padding-top: 3px;
}
