.ctaButton .root {
  margin: 0 5px 0 0;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.5px;
  background-image: none;
  border: 0;
  color: var(--neutral-100);
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  line-height: 20px;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 25%);
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  position: relative;
}

.ctaButton .disabled {
  cursor: not-allowed;
}

.ctaButton .red {
  background-color: var(--primary-500);
  color: #eee;
}

.ctaButton .red:hover {
  background-color: var(--primary-700);
}

.ctaButton .grey {
  background-color: var(--neutral-500);
  color: var(--neutral-900);
}

.ctaButton .lightGrey {
  background-color: var(--neutral-200);
}

.ctaButton .grey:hover {
  background-color: var(--neutral-400);
}

.ctaButton .insertRoot {
  background-color: var(--neutral-200);
  border-color: var(--neutral-300) var(--neutral-300) var(--neutral-500) var(--neutral-500);
  border-radius: 4px;
  border: 1px solid var(--neutral-400);
  color: var(--neutral-1000);
  cursor: pointer;
  font-size: 12px;
  height: 28px;
  line-height: 20px;
  margin: 10px 10px 10px 0;
  padding: 0 15px;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  width: auto;
}

.ctaButton .insertRoot:hover {
  color: var(--neutral-500);
}

.ctaButton .isLoading {
  background-color: var(--neutral-400);
  color: var(--neutral-100);
}

.ctaButton .isLoading:hover {
  background-color: var(--neutral-400);
  color: var(--neutral-100);
}

.ctaButton .large {
  font-size: 16px;
  padding: 10px 17px;
  font-weight: normal;
}

.ctaButton .small {
  font-size: 14px;
  padding: 5px 10px;
}

.ctaButton .loadingLabel {
  opacity: 0;
}

.ctaButton .dotsIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -1px;
}

.ctaButton .loadingImage {
  max-width: 100%;
  margin-right: 6px;
  vertical-align: middle;
  border: 0;
  width: 16px;
  height: 16px;
}

.ctaButton .loadingContainer {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}
