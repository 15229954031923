.root {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.175);
  padding: 0.25rem 0.5rem;
  border: 1px solid var(--neutral-400);
  border-radius: 3px;
}

.root .passwordToolTipList {
  display: flex;
  flex-direction: column;
}

.root .passwordToolTipText {
  font-family: 'Open Sans', sans-serif;
  padding: 0.25rem 0.5rem;
  font-weight: 400;
  line-height: 15px;
}

.root .error {
  color: red;
}

.root .pass {
  color: green;
}

.root .default {
  color: var(--neutral-600);
}
