body .orgForm {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
}

.orgTable .createButton {
  margin: 10px 0;
}

.orgForm .orgLabel {
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 550;
  margin: 0;
  padding-top: 14px;
  text-align: left;
  width: 100px;
}

.orgForm .orgRoot {
  display: flex;
  margin: 0 0 5px;
}

.orgForm .orgRadio {
  display: flex;
  align-items: center;
}

.orgForm .orgButtons {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 0 30px;
  text-align: center;
}

.orgForm .orgHeader {
  float: left;
  font-family: 'Open Sans', sans-serif;
  font-size: 21px;
  font-weight: normal;
  margin-bottom: 30px;
  padding: 0;
}

.orgForm .orgHr {
  margin: 15px 0;
  border: 1px solid var(--neutral-300);
}

.orgForm .orgCreate {
  margin-right: 10px;
}

.orgForm .orgCancel {
  margin-left: 10px;
}

.orgForm .orgChild {
  display: flex;
  font-family: 'Open Sans', sans-serif;
  margin: 12px;
}

.orgForm .orgArrow {
  margin: 0 10px;
  font-size: 21px;
}
