body .customerSupport {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customerSupport .containerText {
  margin: 10px 0;
}

.customerSupport .inputContainer {
  display: flex;
  padding-bottom: 35px;
  width: 300px;
}

.customerSupport .passwordInput {
  display: flex;
  width: 200px;
}

.customerSupport .input {
  flex: 1;
  padding-right: 10px;
}

.customerSupport .userInfo {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  font-size: 20px;
  align-items: center;
}

.customerSupport .imageCell {
  text-align: center;
  width: 45px;
  margin-left: 20px;
}

.customerSupport .imageCell img {
  height: 45px;
}

.customerSupport .accountData {
  display: flex;
  flex-direction: column;
  min-height: auto;
  margin: 40px 0 10px 30px;
  line-height: 20px;
}

.customerSupport .spacing {
  margin-top: 60px;
}

.customerSupport .accountItem {
  display: flex;
  font-weight: 400;
  word-break: break-word;
}

.customerSupport .buttonContainer {
  display: flex;
  margin-top: 10px;
}

.customerSupport .viewContainer {
  margin-top: 10px;
}

.customerSupport .containerText {
  font-size: 14px;
  font-weight: 400;
}

.customerSupport .passwordText {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 600;
}

.customerSupport .strikeThrough {
  text-decoration: line-through;
}

.customerSupport .redText {
  color: var(--primary-500);
}
