body .root {
  background-color: var(--neutral-100);
  height: 100%;
  width: 100%;
  min-height: 450px;
  padding: 10px 30px 60px 30px;
  border: solid 2px var(--neutral-300);
  border-left: none;
  min-width: 900px;
}
