.root.root {
  display: flex;
  margin-top: 2px;
  margin-bottom: 5px;
  background: transparent;
}

.root.outgoing {
  margin-right: 15px;
  justify-content: end;
}

.root.incoming {
  margin-left: 15px;
}

.root.incoming.group {
  margin-left: 50px;
}

.container.container {
  display: flex;
  width: 50%;
}

.container.outgoing {
  flex-direction: row-reverse;
}

.container.incoming {
  flex-direction: row;
}

.list.list {
  display: flex;
  padding: 2px;
  flex-wrap: wrap;
  align-items: center;
}

.list.outgoing {
  justify-content: end;
}

.item.item {
  padding: 2px 5px;
  border: 1px var(--neutral-300) solid;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 3px;
}

.item.item.self {
  border: solid 1px var(--secondary-300);
  background: var(--cool-neutral-300);
}

.item.item:hover {
  border: 1px var(--neutral-300) solid;
  background: var(--neutral-300);
}

.item.incoming {
  margin-left: 3px;
}

.item.outgoing {
  margin-right: 3px;
}

.reaction.reaction {
  display: flex;
  align-items: center;
  height: 26px;
}

.emoji.emoji {
  margin-right: 4px;
}

.multiple.multiple {
  margin-right: 2px;
  margin-left: 2px;
}

.count.count {
  color: var(--neutral-700);
  font-size: 11px;
}

.addItem {
  display: flex;
  align-items: center;
}

.addItem:hover svg {
  fill: var(--neutral-100);
}

.addItem.addItem:hover {
  border: 1px var(--neutral-300) solid;
  background: var(--neutral-300);
}

.tooltip {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  font-weight: 800;
}

.tooltip:first-child {
  margin-top: 0;
}

.tooltip .emoji {
  font-size: 32px;
  line-height: 34px;
  margin-right: 12px;
}

.tooltip span {
  color: #ccc;
  font-weight: 400;
}
