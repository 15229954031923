body .root {
  background: url('../../images/sprite_icons.png') no-repeat left center;
  background-position: -27px -964px;
  height: 35px;
  padding: 7px 25px;
  margin-top: -7px;
  cursor: pointer;
  display: flex;
}

body:global(.theme-dark) .root {
  background: url('../../images/sprite_icons_dark.png') no-repeat left center;
  background-position: 10px -1084px;
  height: 30px;
  margin: 0;
}
