body .root {
  cursor: pointer;
  display: inline-block;
}

.root .label {
  margin-left: 5px;
}

.root.checkboxMargin {
  margin-bottom: 8px;
}
