.header {
  color: var(--secondary-700);
  font-size: 18px !important;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 34px 32px 0 !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  pointer-events: auto;
  cursor: pointer;
  color: var(--secondary-700) !important;
  margin: 34px 32px 0 !important;
}

.close:focus-visible {
  outline: 3px solid var(--secondary-500);
}

.body {
  padding: 0;
}

.searchContainer {
  padding: 10px 40px 5px !important;
}

.unitContainer {
  background: var(--neutral-150);
  border: 1px solid var(--neutral-300) !important;
  border-radius: 6px;
  margin: 50px 50px 30px !important;
  height: 230px;
  overflow-y: auto;
}

.unitContainerEmpty {
  background: var(--neutral-150);
  border: 1px solid var(--neutral-300) !important;
  border-radius: 6px;
  margin: 50px 50px 30px !important;
  height: 230px;
  overflow-y: hidden;
}

.unitItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px !important;
}

.buttons {
  align-items: center;
  background-color: var(--neutral-150);
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  height: 84px;
  padding: 25px 30px !important;
}

.parentAreaName {
  color: var(--neutral-600);
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.noRoomsAvailable {
  color: var(--neutral-600);
  padding: 10px !important;
}

.button {
  border-radius: 6px;
  box-sizing: border-box !important;
  cursor: pointer;
  font-family: 'Avenir Next', serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  padding: 10px 26px !important;
  text-align: center;
  width: 92px;
  height: 34px;
  left: 302px;
  top: 25px;
}

.cancel.cancel {
  border: 1px solid var(--cool-neutral-700);
  color: var(--cool-neutral-700);
  cursor: pointer;
}

.cancel.cancel:hover {
  color: var(--neutral-100);
  background-color: var(--cool-neutral-700);
}

.save.save {
  border: 1px solid var(--secondary-600);
  color: var(--secondary-600);
  cursor: pointer;
}

.save.save:hover {
  color: var(--neutral-100);
  background-color: var(--secondary-700);
}

.save.save:disabled,
.save.save.disabled:hover {
  border: 1px solid var(--neutral-300);
  background-color: var(--neutral-100);
  color: var(--neutral-300);
  cursor: default;
}

.disabled.disabled,
.disabled.disabled:hover {
  border: 1px solid var(--neutral-300);
  color: var(--neutral-300);
  cursor: default;
}
