body .root {
  padding: 14px 0;
  border: 2px solid var(--neutral-200);
  color: var(--neutral-1000);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

body .selected {
  background-color: var(--neutral-100);
  border: 2px solid var(--neutral-300);
  border-right: none;
}
